import '../styles/styles.css';

import * as Sentry from '@sentry/nextjs';

import PropTypes from 'prop-types';
import { useEffect } from 'react';

function MyApp({ Component, pageProps }) {
  Sentry.init({
    dsn: 'https://cf223847c1ab4432839d981869b89391@o1177519.ingest.sentry.io/6276525',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });

  useEffect(() => {
    document.documentElement.style.opacity = 1;
  }, []);

  return <Component {...pageProps} />;
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object
};

export default MyApp;
